div.DraftEditor-root {
  height: 80px;
  display: inline-block;
  width: 100%;
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}
