@font-face {
  font-family: "Proxima";
  src: local("ProximaNova-Regular"),
    url("../fonts/ProximaNova-Bold.otf") format("opentype"),
    url("../fonts/ProximaNova-Black.otf") format("opentype"),
    url("../fonts/ProximaNova-Extrabold.otf") format("opentype"),
    url("../fonts/ProximaNova-Thin.otf") format("opentype");
}

@font-face {
  font-family: "FavoritExtended";
  src: url("../fonts/ABCFavoritExtended-Bold-Trial.woff2") format("woff2"),
    url("../fonts/ABCFavoritExtended-Bold-Trial.woff") format("woff");
}

#homepage .section {
  padding: 4rem 220px 2rem;
  text-align: left;
}
#homepage .hero-heading {
  font-size: 3em;
  padding-bottom: 10px;
  line-height: 1.25em;
  font-weight: 800;
}
#homepage .logo {
  padding: 6em;
}
#homepage .logo img {
  position: absolute;
  top: 150px;
  left: 220px;
  width: 160px;
}
#homepage .get-help {
  border-bottom: 1px solid #ddd;
}
#homepage .links {
  font-size: 0.8em;
}
#homepage .links a {
  color: darkgrey;
}
@media only screen and (max-width: 800px) {
  #homepage body {
    background-size: 720px;
    background-position: -35px 0;
  }
  #homepage .section {
    padding: 2rem 60px 0rem;
  }
  #homepage .logo {
    padding: 3em;
  }
  #homepage .logo img {
    position: absolute;
    top: 70px;
    left: 70px;
    width: 125px;
  }
  #homepage .hero-heading {
    font-size: 2em;
    line-height: 1.25em;
  }
}
